import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { FaUser, FaEnvelope, FaPhone, FaBuilding } from 'react-icons/fa'
import Modal from '../components/Modal'

const Separator = styled.div`
  border-color: #fe1d03;
  border-top-width: 4px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 100px;

  border-bottom-style: solid;
  border-top-style: solid;

  height: auto;
  border-bottom-width: 0;
`

const CircleIcon = styled.div`
  -webkit-animation-duration: 350ms;
  animation-duration: 350ms;

  height: 102px;
  width: 102px;
  line-height: 57px;
  border-color: #fe1d03;
  border-width: 2px;
  border-style: solid;
  background-color: #fe1d03;
  margin-right: 20px;
  box-sizing: content-box;
  border-radius: 50%;

  border-color: #fe1d03;
  border-width: 6px;
  background-color: #fe1d03;
  box-sizing: content-box;
  height: 90px;
  width: 90px;
  line-height: 90px;
  border-radius: 50%;
  position: relative;
  top: auto;
  left: auto;
  margin: 0 auto;
  margin-bottom: 15px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 45px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const Contacto = () => {
  const [modal, setModal] = useState({
    active: false,
    type: null,
    title: null,
    content: null,
  })

  const closeModal = () => setModal({ ...modal, active: false })

  const showModalSuccess = () =>
    setModal({
      ...modal,
      active: true,
      type: 'is-success',
      title: 'Mensaje recibido',
      content: 'En breve nos pondremos en contacto',
    })

  const showModalError = () =>
    setModal({
      ...modal,
      active: true,
      type: 'is-danger',
      title: 'Error',
      content: 'Ocurrio un error al enviar tu mensaje',
    })

  return (
    <Layout>
      <SEO
        title="Nosotros"
        keywords={[
          `ember`,
          `apps`,
          `páginas web`,
          `aplicaciones`,
          `contacto`,
          `contacanos`,
        ]}
      />
      <section className="section">
        <div className="container" style={{ marginTop: '150px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <h1 className="title is-1">Empecemos a trabajar</h1>
            <Separator />
          </div>

          <div className="columns">
            <div className="column is-8 is-offset-2">
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  phone: '',
                  company: '',
                  content: '',
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .min(3, 'mínimo 3 caracteres')
                    .max(50, 'máximo 50 caracteres')
                    .required('Introduce tu nombre'),
                  email: Yup.string()
                    .email('Correo invalido')
                    .required('Introduce tu correo electrónico'),
                  content: Yup.string()
                    .min(5, 'mínimo 5 caracteres')
                    .max(1000, 'máximo 1000 caracteres')
                    .required('Introduce tu mensaje'),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const res = await fetch('/api/contact', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(values),
                    })

                    if (res.ok) {
                      showModalSuccess()
                    } else {
                      showModalError()
                    }
                    setSubmitting(false)
                  } catch (error) {
                    showModalError()
                    setSubmitting(false)
                  }
                }}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <Field
                              className={`input ${
                                errors.name && touched.name ? 'is-danger' : null
                              }`}
                              type="text"
                              name="name"
                              placeholder="Nombre"
                            />
                            <span className="icon is-small is-left">
                              <FaUser />
                            </span>
                          </p>
                          <ErrorMessage
                            name="name"
                            component="p"
                            className="help is-danger"
                          />
                        </div>
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <Field
                              className={`input ${
                                errors.email && touched.email
                                  ? 'is-danger'
                                  : null
                              }`}
                              type="email"
                              name="email"
                              placeholder="Correo Electrónico"
                            />
                            <span className="icon is-small is-left">
                              <FaEnvelope />
                            </span>
                          </p>
                          <ErrorMessage
                            name="email"
                            component="p"
                            className="help is-danger"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <Field
                              className="input"
                              type="tel"
                              name="phone"
                              placeholder="Teléfono"
                            />
                            <span className="icon is-small is-left">
                              <FaPhone />
                            </span>
                          </p>
                        </div>
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <Field
                              className="input"
                              type="text"
                              name="company"
                              placeholder="Compañía"
                            />
                            <span className="icon is-small is-left">
                              <FaBuilding />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <Field
                              name="content"
                              component="textarea"
                              className={`textarea ${
                                errors.content && touched.content
                                  ? 'is-danger'
                                  : null
                              }`}
                              placeholder="¿En que podemos ayudarte?"
                            />
                          </div>
                          <ErrorMessage
                            name="content"
                            component="p"
                            className="help is-danger"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <button
                              className="button is-primary is-outlined"
                              type="sumbit"
                              disabled={isSubmitting}
                            >
                              ENVIAR
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <section className="container">
          <div className="columns">
            <div className="column">
              <a
                href="tel:+5215510528346"
                target="_blank"
                rel="noopener noreferrer"
                alt="telefono"
              >
                <CircleIcon>
                  <FaPhone />
                </CircleIcon>
              </a>
              <div
                className="content"
                style={{
                  flex: '1 1 auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <h1>TELEFONO</h1>
                <a
                href="tel:+5215510528346"
                target="_blank"
                rel="noopener noreferrer"
                alt="telefono"
              >52 1 55 1052 8346</a>
                <a
                href="tel:+5215559628491"
                target="_blank"
                rel="noopener noreferrer"
                alt="telefono"
              >52 1 55 5962 8491</a>
              </div>
            </div>

            <div className="column">
              <a
                href="mailto:ventas@ember.com.mx"
                target="_blank"
                rel="noopener noreferrer"
                alt="email"
              >
                <CircleIcon>
                  <FaEnvelope />
                </CircleIcon>
              </a>
              <div
                className="content"
                style={{
                  flex: '1 1 auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <h1>EMAIL</h1>
                <a
                href="mailto:ventas@ember.com.mx"
                target="_blank"
                rel="noopener noreferrer"
                alt="email"
              >ventas@ember.com.mx</a>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Modal
        active={modal.active}
        type={modal.type}
        title={modal.title}
        content={modal.content}
        onClose={closeModal}
      />
    </Layout>
  )
}

export default Contacto
