import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'

const Modal = ({ active, type, title, content, onClose }) => {
  return (
    <div className={`modal ${active ? 'is-active' : null}`}>
      <div className="modal-background" onClick={onClose} />
      <div className="modal-content">
        <article className={`message ${type}`}>
          <div className="message-header">
            <p>{title}</p>
            <div className="delete" aria-label="close" onClick={onClose}>
              <FaTimesCircle style={{ fontSize: '24px' }} />
            </div>
          </div>
          <div className="message-body">{content}</div>
        </article>
      </div>
    </div>
  )
}

export default Modal
